import React from 'react';
import Img from '../../utils/OptimizedImage';
import polyTopWhite from '../../images/polyTopWhite.svg';
import polyLower from '../../images/polyLower.svg';
import polyRightWhite from '../../images/polyRightWhite.svg';

const PolyIcons = () => (
  <>
    <figure className="top-poly">
      <Img src={polyTopWhite} alt="Polygon" />
    </figure>
    <figure className="bottom-poly">
      <Img src={polyLower} alt="Polygon" />
    </figure>
    <figure className="right-poly">
      <Img src={polyRightWhite} alt="Polygon" />
    </figure>
  </>
);

export default PolyIcons;
